import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import htmlDocx from 'html-docx-js/dist/html-docx';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FaRegUser } from 'react-icons/fa';
import dayjs from '../../utils/timeUtils';
import JoditEditor from 'jodit-react';
import {
  FormHelperText,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Grid,
  Typography,
} from '@mui/material';

import {
  CONTRACT_EDITOR_CONFIG,
  MSG_REQUIRED_FIELD,
} from '../../utils/contants';
import { commaStyle } from '../../utils/functions';
import { apiHeader, BaseURL, imageUrl } from '../../config/apiUrl';
import { Get, Patch, Post } from '../../Axios/AxiosFunctions';
import SideBarSkeleton from '../../Component/SideBarSkeleton';
import { Button } from '../../Component/Button/Button';
import ModalSkeleton from '../../modals/ModalSkeleton';
import ContractInputFieldModal from '../../modals/ContractInputFieldModal';
import AddSignatureModal from '../../modals/AddSignatureModal';
import classes from './AddContractAgreement.module.css';

const validationSchema = yup.object().shape({
  template: yup.string().required(MSG_REQUIRED_FIELD),
  content: yup.string().required(MSG_REQUIRED_FIELD),
});

const formateDate = (date) => {
  return `${dayjs(new Date(date))?.format('MM-DD-YYYY')}`;
};

const AddContractAgreement = ({ editData }) => {
  const editorRef = useRef();

  const navigate = useNavigate();

  const {
    access_token: accessToken,
    user,
    company_id: companyId,
  } = useSelector((state) => state?.authReducer);
  const { logo, companyName, companyAddress } = useSelector(
    (state) => state?.companyReducer,
  );

  const baseApiUrl = BaseURL(`contract-agreement`);

  const [isOpenContractModal, setIsOpenContractModal] = useState(false);
  const [listings, setListings] = useState([]);
  const [entities, setEntities] = useState([]);
  const [contractTemplates, setContractTemplates] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [brokers, setBrokers] = useState([]);
  const [buyers, setBuyers] = useState([]);
  const [cooperatingBroker, setCooperatingBroker] = useState([]);
  const [thirdPartyContacts, setThirdPartyContacts] = useState([]);

  const [isOpenContractInputFieldModal, setIsOpenContractInputFieldModal] =
    useState(false);

  const [companyLogoBase64, setCompanyLogoBase64] = useState(null);
  const [showSignatureModal, setShowSignatureModal] = useState(false);

  const imageUrlToBase64 = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
      reader.onerror = reject;
    });
  };

  const getAgreementContent = (content) => {
    if (content) {
      let replacedContent = content || '';

      replacedContent = replacedContent?.replaceAll(
        '*|company_logo|*',
        `   
          <img style="
            width: 200px;
            height: 100%;
            object-fit: contain;
            object-position: center;
          " src="${companyLogoBase64 || ''}" alt="logo" />`,
      );

      replacedContent = replacedContent?.replaceAll(
        '*|company_name|*',
        values?.company_name || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|company_address|*',
        values?.company_address || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|entity_name|*',
        values?.entity?.name || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|entity_city|*',
        values?.entity_city || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|entity_country|*',
        values?.entity_country || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|entity_state|*',
        values?.entity_state || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|entity_street|*',
        values?.entity_street || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|entity_zip|*',
        values?.entity_zip || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|entity_phone|*',
        values?.entity_phone || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|listing_brokerage_name|*',
        values?.listing_brokerage_name || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|listing_brokerage_address|*',
        values?.listing_brokerage_address || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|Seller_Name|*',
        values?.seller?.name || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|seller_email|*',
        values?.seller_email || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|seller_address|*',
        values?.seller_address || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|seller_phone|*',
        values?.seller_phone || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|seller_title|*',
        values?.seller_title || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|seller_signature_date|*',
        values?.seller_signature_date
          ? formateDate(values?.seller_signature_date)
          : '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|Buyer_Name|*',
        values?.buyer?.name || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|buyer_email|*',
        values?.buyer_email || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|buyer_address|*',
        values?.buyer_address || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|buyer_phone|*',
        values?.buyer_phone || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|buyer_title|*',
        values?.buyer_title || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|buyer_signature_date|*',
        values?.buyer_signature_date
          ? formateDate(values?.buyer_signature_date)
          : '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|broker_name|*',
        values?.broker?.name || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|broker_firm_name|*',
        values?.broker_firm_name || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|broker_provided_info|*',
        values?.broker_provided_info || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|Business_Name|*',
        values?.business_name || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|Business_Address|*',
        values?.business_address || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|cooperating_brokerage_name|*',
        values?.cooperating_brokerage_name || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|cooperating_brokerage_address|*',
        values?.cooperating_brokerage_address || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|commission_percentage|*',
        values?.commission_percentage &&
          Number(values?.commission_percentage) >= 0
          ? `${Number(values?.commission_percentage)}%`
          : '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|commission_payment_terms|*',
        values?.commission_payment_terms || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|cooperating_broker_name|*',
        values?.cooperating_broker_name?.name || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|cooperating_broker_title|*',
        values?.cooperating_broker_title || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|cooperating_broker_phone|*',
        values?.cooperating_broker_phone || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|cooperating_broker_email|*',
        values?.cooperating_broker_email || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|Agreement_Title|*',
        values?.agreement_title || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|Agreement_Date|*',
        values?.agreement_date ? formateDate(values?.agreement_date) : '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|closing_date|*',
        values?.closing_date ? formateDate(values?.closing_date) : '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|document_date|*',
        values?.document_date ? formateDate(values?.document_date) : '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|Termination_Reason|*',
        values?.termination_reason || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|Clause_Reference|*',
        values?.clause_reference || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|Due_Diligence_Deadline|*',
        values?.due_diligence_deadline || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|Document_List|*',
        values?.document_list || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|earnest_money_amount|*',
        values?.earnest_money_amount &&
          Number(values?.earnest_money_amount) >= 0
          ? `$${commaStyle(values?.earnest_money_amount)}`
          : '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|escrow_agent_name|*',
        values?.escrow_agent_name?.name || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|escrow_company_name|*',
        values?.escrow_company_name || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|purchase_price|*',
        values?.purchase_price && Number(values?.purchase_price) >= 0
          ? `$${commaStyle(values?.purchase_price)}`
          : '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|down_payment|*',
        values?.down_payment && Number(values?.down_payment) >= 0
          ? `$${commaStyle(values?.down_payment)}`
          : '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|loan_at_closing|*',
        values?.loan_at_closing && Number(values?.loan_at_closing) >= 0
          ? `$${commaStyle(values?.loan_at_closing)}`
          : '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|note_at_closing|*',
        values?.note_at_closing && Number(values?.note_at_closing)
          ? `$${commaStyle(values?.note_at_closing)}`
          : '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|start_date|*',
        values?.start_date ? formateDate(values?.start_date) : '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|end_date|*',
        values?.end_date ? formateDate(values?.end_date) : '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|sale_commission_amount|*',
        values?.sale_commission_amount &&
          Number(values?.sale_commission_amount) >= 0
          ? `$${commaStyle(values?.sale_commission_amount)}`
          : '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|sale_commission_percentage|*',
        values?.sale_commission_percentage &&
          Number(values?.sale_commission_percentage) >= 0
          ? `${Number(values?.sale_commission_percentage)}%`
          : '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|RE_commission_amount|*',
        values?.RE_commission_amount &&
          Number(values?.RE_commission_amount) >= 0
          ? `$${commaStyle(values?.RE_commission_amount)}`
          : '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|RE_commission_percentage|*',
        values?.RE_commission_percentage &&
          Number(values?.RE_commission_percentage) >= 0
          ? `${Number(values?.RE_commission_percentage)}%`
          : '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|equipment_price|*',
        values?.equipment_price && Number(values?.equipment_price) >= 0
          ? `$${commaStyle(values?.equipment_price)}`
          : '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|inventory_price|*',
        values?.inventory_price && Number(values?.inventory_price) >= 0
          ? `$${commaStyle(values?.inventory_price)}`
          : '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|term_cash|*',
        values?.term_cash || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|term_sba|*',
        values?.term_sba || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|term_conventional|*',
        values?.term_conventional || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|term_other|*',
        values?.term_other || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|seller_carry_years|*',
        values?.seller_carry_years || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|seller_carry_rate|*',
        values?.seller_carry_rate || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|acceptance_deadline|*',
        values?.acceptance_deadline || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|inventory_margin|*',
        values?.inventory_margin || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|buyer_execution_date_1|*',
        values?.buyer_execution_date_1
          ? formateDate(values?.buyer_execution_date_1)
          : '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|buyer_execution_date_2|*',
        values?.buyer_execution_date_2
          ? formateDate(values?.buyer_execution_date_2)
          : '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|seller_execution_date|*',
        values?.seller_execution_date
          ? formateDate(values?.seller_execution_date)
          : '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|date_executed|*',
        values?.date_executed ? formateDate(values?.date_executed) : '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|today_date|*',
        values?.today_date ? formateDate(values?.today_date) : '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|listing_broker_name|*',
        values?.listing_broker_name?.name || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|listing_broker_title|*',
        values?.listing_broker_title || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|listing_broker_email|*',
        values?.listing_broker_email || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|listing_broker_phone|*',
        values?.listing_broker_phone || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|listing_broker_telephone|*',
        values?.listing_broker_telephone || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|listing_broker_signature_date|*',
        values?.listing_broker_signature_date
          ? formateDate(values?.listing_broker_signature_date)
          : '',
      );

      return replacedContent;
    } else {
      return '';
    }
  };

  const convertToBase64 = (htmlContent) => {
    const html = `
      <!DOCTYPE html>
        <html lang="en">
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
        </head>

        <body>
          ${htmlContent}
        </body>
      </html>
    `;

    return new Promise((resolve, reject) => {
      const converted = htmlDocx.asBlob(html, {
        margins: { top: 520, bottom: 520, left: 520, right: 520 },
      });
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result.split(',')[1]; // Extract the Base64 part
        resolve(base64String); // Resolve the Promise with the Base64 string
      };

      reader.onerror = (error) => reject(error); // Handle any errors that may occur

      reader.readAsDataURL(converted);
    });
  };

  const {
    errors,
    values,
    touched,
    handleSubmit,
    setFieldValue,
    setValues,
    isSubmitting,
    setSubmitting,
    resetForm,
    handleBlur,
  } = useFormik({
    initialValues: {
      status: editData?.status || 'draft',
      template: editData?.template,
      content: editData?.content || '',
      recipients: editData?.recipients || [],

      // for template
      seller_carry_years: editData?.seller_carry_years || 0,
      seller_carry_rate: editData?.seller_carry_rate || 0,
      acceptance_deadline: editData?.acceptance_deadline,
      inventory_margin: editData?.inventory_margin || 0,

      // Listing
      listing: editData?.listing,

      // Entity
      entity: editData?.entity,
      entity_street: editData?.entity_street,
      entity_city: editData?.entity_city,
      entity_state: editData?.entity_state,
      entity_country: editData?.entity_country,
      entity_zip: editData?.entity_zip,
      entity_phone: editData?.entity_phone,

      listing_brokerage_name:
        editData?.listing_brokerage_name || companyName || '',
      listing_brokerage_address:
        editData?.listing_brokerage_address || companyAddress || '',

      business_name: editData?.business_name || '',
      business_address: editData?.business_address || '',

      company_name: editData?.company_name || '',
      company_address: editData?.company_address || '',

      // Buyer
      buyer: editData?.buyer,
      buyer_email: editData?.buyer_email,
      buyer_address: editData?.buyer_address,
      buyer_phone: editData?.buyer_phone,
      buyer_title: editData?.buyer_title,
      buyer_signature_date: editData?.buyer_signature_date,

      // Seller
      seller: editData?.seller,
      seller_email: editData?.seller_email,
      seller_address: editData?.seller_address,
      seller_phone: editData?.seller_phone,
      seller_title: editData?.seller_title,
      seller_signature_date: editData?.seller_signature_date,

      // broker: '',
      broker: editData?.broker || '',
      broker_firm_name: editData?.broker_firm_name || '',
      broker_provided_info: editData?.broker_provided_info || '',

      cooperating_brokerage_name: editData?.cooperating_brokerage_name || '',
      cooperating_brokerage_address:
        editData?.cooperating_brokerage_address || '',
      commission_percentage: editData?.commission_percentage || '',
      commission_payment_terms: editData?.commission_payment_terms || '',

      //Cooperating Broker
      cooperating_broker_name: editData?.cooperating_broker_name || '',
      cooperating_broker_title: editData?.cooperating_broker_title || '',
      cooperating_broker_phone: editData?.cooperating_broker_phone || '',
      cooperating_broker_email: editData?.cooperating_broker_email || '',

      // Agreement
      agreement_title: editData?.agreement_title || '',
      agreement_date: editData?.agreement_date || '',
      closing_date: editData?.closing_date || '',
      document_date: editData?.document_date || null,

      //
      termination_reason: editData?.termination_reason || '',
      clause_reference: editData?.clause_reference || '',
      due_diligence_deadline: editData?.due_diligence_deadline || '',
      document_list: editData?.document_list || '',

      earnest_money_amount: editData?.earnest_money_amount || '',
      escrow_agent_name: editData?.escrow_agent_name || '',
      escrow_company_name: editData?.escrow_company_name || '',

      today_date:
        editData?.today_date ||
        `${dayjs(new Date())?.format('YYYY-MM-DD')}` ||
        '',
      buyer_execution_date_1: editData?.buyer_execution_date_1 || '',
      buyer_execution_date_2: editData?.buyer_execution_date_2 || '',
      seller_execution_date: editData?.seller_execution_date || '',
      date_executed: editData?.date_executed || '',

      purchase_price: editData?.purchase_price || '',
      down_payment: editData?.down_payment || '',
      loan_at_closing: editData?.loan_at_closing || '',
      note_at_closing: editData?.note_at_closing || '',
      start_date: editData?.start_date || '',
      end_date: editData?.end_date || '',
      sale_commission_amount: editData?.sale_commission_amount || '',
      sale_commission_percentage: editData?.sale_commission_percentage || '',
      RE_commission_amount: editData?.RE_commission_amount || '',
      RE_commission_percentage: editData?.RE_commission_percentage || '',
      equipment_price: editData?.equipment_price || '',
      inventory_price: editData?.inventory_price || '',
      term_cash: editData?.term_cash || '',
      term_sba: editData?.term_sba || '',
      term_conventional: editData?.term_conventional || '',
      term_other: editData?.term_other || '',

      // Listing Broker
      listing_broker_name: editData?.listing_broker_name || '',
      listing_broker_email: editData?.listing_broker_email || '',
      listing_broker_phone: editData?.listing_broker_phone || '',
      listing_broker_title: editData?.listing_broker_title || '',
      listing_broker_telephone: editData?.listing_broker_telephone || '',
      listing_broker_signature_date:
        editData?.listing_broker_signature_date || '',
    },
    validationSchema,
    onSubmit: async (value) => {
      const body = {
        ...value,
        buyer: value?.buyer?._id,
        listing: value?.listing?._id,
        seller: value?.seller?._id,
        broker: value?.broker?._id,
        entity: value?.entity?._id,
        cooperating_broker_name: value?.cooperating_broker_name?._id,
        escrow_agent_name: value?.escrow_agent_name?._id,
        listing_broker_name: value?.listing_broker_name?._id,
        contentBase64: '',
      };

      setSubmitting(true);

      body.contentBase64 = await convertToBase64(
        getAgreementContent(value?.content),
      );

      if (editData?._id) {
        const response = await Patch(
          `${baseApiUrl}/${editData?._id}`,
          body,
          apiHeader(accessToken),
        );

        if (response !== undefined) {
          if (value?.status === 'sent') {
            toast.success(`Contract Agreement Sent Successfully.`);
            navigate('/contract-sent');
          } else {
            toast.success(`Contract Agreement Edited Successfully.`);
            navigate('/contract-agreements');
          }

          setSubmitting(false);
          resetForm();
        }
      } else {
        const response = await Post(baseApiUrl, body, apiHeader(accessToken));

        if (response !== undefined) {
          if (value?.status === 'sent') {
            toast.success(`Contract Agreement Sent Successfully.`);
            navigate('/contract-sent');
          } else {
            toast.success(`Contract Agreement Created Successfully.`);
            navigate('/contract-agreements');
          }
          setSubmitting(false);
          resetForm();
        }
      }
    },
  });

  const getAllContractTemplates = async () => {
    if (accessToken && accessToken?.length > 0) {
      const url = BaseURL(`contract-template`);

      const response = await Get(url, accessToken);

      if (response?.status === 200 && response?.data?.result) {
        setContractTemplates(response?.data?.result);
      }
    }
  };

  const getUsers = async () => {
    if (accessToken && accessToken?.length > 0) {
      const url = BaseURL(
        `users/get-admins?roles=${encodeURIComponent(['seller'])}`,
      );

      const response = await Get(url, accessToken, companyId);

      if (response?.status === 200 && response?.data?.data) {
        const sellersData = response?.data?.data?.filter((user) =>
          user?.role?.includes('seller'),
        );

        setSellers(sellersData || []);
      }

      const buyerResponse = await Get(
        BaseURL(`users/get-admins?roles=buyer`),
        accessToken,
        companyId,
      );

      if (buyerResponse?.status === 200 && buyerResponse?.data?.data) {
        setBuyers(buyerResponse?.data?.data);
      }
    }
  };

  const get3PartyBroker = async () => {
    if (accessToken && accessToken?.length > 0) {
      const url = BaseURL(
        `users/get-admins?roles=${encodeURIComponent(['3rd-party-broker'])}`,
      );

      const response = await Get(url, accessToken, companyId);

      if (response?.status === 200 && response?.data?.data) {
        setCooperatingBroker(response?.data?.data);
      }
    }
  };

  const getBrokers = async () => {
    if (accessToken && accessToken?.length > 0) {
      const url = BaseURL(
        `users/get-admins?roles=${encodeURIComponent(['broker'])}`,
      );

      const response = await Get(url, accessToken, companyId);

      if (response?.status === 200 && response?.data?.data) {
        setBrokers(response?.data?.data);
      }
    }
  };

  const get3PartyContacts = async () => {
    if (accessToken && accessToken?.length > 0) {
      const url = BaseURL(
        `users/get-admins?roles=${encodeURIComponent(['3rd-party-contacts'])}`,
      );

      const response = await Get(url, accessToken, companyId);

      if (response?.status === 200 && response?.data?.data) {
        setThirdPartyContacts(response?.data?.data);
      }
    }
  };

  const getListing = async () => {
    const url = BaseURL(`business/admin/all`);

    const response = await Get(url, accessToken, companyId);

    if (response?.status === 200 && response?.data?.business) {
      setListings(response?.data?.business);
    }
  };

  const getEntities = async () => {
    const url = BaseURL(`entity`);

    const response = await Get(url, accessToken, companyId);

    if (response?.status === 200 && response?.data?.data) {
      setEntities(response?.data?.data);
    }
  };

  useEffect(() => {
    if (accessToken) {
      getAllContractTemplates();
      getUsers();
      getBrokers();
      get3PartyBroker();
      get3PartyContacts();
      getListing();
      getEntities();
    }
  }, [accessToken]);

  const handleLogo = async () => {
    const base64 = await imageUrlToBase64(imageUrl(logo));
    setCompanyLogoBase64(base64);
  };

  useEffect(() => {
    if (logo) {
      handleLogo();
    }
  }, [logo]);

  // Handle Drag and Drop
  const handleOnDragEnd = (result) => {
    const { source, destination } = result;

    // If dropped outside the list or no movement
    if (!destination || source.index === destination.index) return;

    // Reorder items based on drag result
    const updatedItems = Array.from(values?.recipients);
    const [removed] = updatedItems.splice(source.index, 1); // Remove the dragged item
    updatedItems.splice(destination.index, 0, removed); // Insert the item in new position

    setFieldValue('recipients', updatedItems); // Update the state with the reordered items
  };

  useEffect(() => {
    if (editorRef?.current?.value) {
      setFieldValue('content', editorRef?.current?.value);
    } else {
      setFieldValue('content', '');
    }
  }, [editorRef?.current?.value]);

  return (
    <>
      <SideBarSkeleton>
        <div className={classes.container_main}>
          <div className={classes.main_heading}>
            <h4>{editData?._id ? 'Edit' : 'Add'} Contract Agreement</h4>
          </div>

          <form
            onSubmit={handleSubmit}
            className={classes.addEmailTemplateModal_main}
          >
            <Row className={classes.input_row}>
              {/* Contract Template select */}
              <FormControl
                fullWidth
                error={touched.type && Boolean(errors.type)}
              >
                <InputLabel id="type-label">Contract Template</InputLabel>
                <Select
                  labelId="type-label"
                  name="template"
                  value={values.template}
                  onChange={(e) => {
                    const value = e?.target?.value;
                    setFieldValue('template', value);
                    setFieldValue(
                      'content',
                      contractTemplates?.find((item) => item?._id == value)
                        ?.content,
                    );
                  }}
                  onBlur={handleBlur}
                  label="Contract Template"
                  MenuProps={{
                    style: {
                      maxHeight: '700px',
                      maxWidth: '400px',
                    },
                  }}
                >
                  {contractTemplates.map((trigger) => (
                    <MenuItem key={trigger._id} value={trigger._id}>
                      {trigger.name}
                    </MenuItem>
                  ))}
                </Select>

                {touched.type && errors.type && (
                  <FormHelperText>{errors.type}</FormHelperText>
                )}
              </FormControl>

              <Button
                type="button"
                className={classes.btn}
                label="Select Contract Field"
                onClick={() => {
                  setIsOpenContractInputFieldModal(
                    !isOpenContractInputFieldModal,
                  );
                }}
              />

              <div className={classes.var_section_main}>
                <Button
                  type="button"
                  label="Select Signature Box"
                  onClick={() => {
                    setShowSignatureModal(true);
                  }}
                />
                <div className={classes.var_section}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                    }}
                  >
                    Signing Order
                  </Typography>

                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <div
                          container
                          spacing={2}
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {values?.recipients?.map((item, index) => (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(provided) => (
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  md={4}
                                  gap={8}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <Typography
                                    variant="p"
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                      justifyContent: 'flex-start',
                                      gap: '10px',
                                      fontSize: '18px',
                                      fontWeight: 400,
                                    }}
                                  >
                                    <FaRegUser size={20} />

                                    <span>
                                      {item?.name} ({item?.role})
                                    </span>
                                  </Typography>
                                </Grid>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </div>

              <div className={classes.textEditor}>
                <JoditEditor
                  ref={editorRef}
                  value={values?.content}
                  config={{
                    ...CONTRACT_EDITOR_CONFIG,
                    events: {
                      afterInit: (e) => {
                        editorRef.current = e;
                      },
                    },
                  }}
                />
              </div>
            </Row>

            <div className={classes.btn_main}>
              <Button
                type="button"
                className={classes.btn}
                label="Preview Contract"
                disabled={!values?.content?.trim()}
                onClick={() => {
                  setIsOpenContractModal(!isOpenContractModal);
                }}
              />

              <Button
                type="submit"
                className={classes.btn}
                label={
                  isSubmitting && values?.status === 'draft'
                    ? 'Submitting...'
                    : 'Save'
                }
                disabled={isSubmitting && values?.status === 'draft'}
                onClick={() => {
                  setFieldValue('status', 'draft');
                }}
              />

              <Button
                type="submit"
                className={classes.btn}
                label={
                  isSubmitting && values?.status === 'sent'
                    ? 'Submitting...'
                    : 'Send For Signature'
                }
                disabled={isSubmitting && values?.status === 'sent'}
                onClick={() => {
                  setFieldValue('status', 'sent');
                }}
              />
            </div>
          </form>
        </div>

        <ModalSkeleton
          show={isOpenContractModal}
          setShow={setIsOpenContractModal}
          borderRadius="20px"
          width="1340px"
          borderLine={false}
          header={'Contract Agreement Preview'}
          showCloseIcon={true}
        >
          <style jsx>{`
            .table_row table {
              border-collapse: collapse;
            }

            .table_row th,
            td {
              border-width: 1px;
            }

            ul,
            li {
              margin: initial;
              list-style-type: unset;
            }
          `}</style>

          <div
            dangerouslySetInnerHTML={{
              __html: getAgreementContent(values?.content),
            }}
            style={{
              maxHeight: '80dvh',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
            className={classes?.table_row}
          />
        </ModalSkeleton>
        {showSignatureModal && (
          <AddSignatureModal
            isOpen={showSignatureModal}
            setIsOpen={setShowSignatureModal}
            recipients={values?.recipients}
            setReciptents={(value) => setFieldValue('recipients', value)}
            sellers={sellers?.map((user) => ({
              name: `${user?.firstName || ''} ${user?.lastName}`,
              ...user,
            }))}
            buyers={buyers?.map((user) => ({
              name: `${user?.firstName || ''} ${user?.lastName}`,
              ...user,
            }))}
            thirdPartyContacts={thirdPartyContacts?.map((user) => ({
              name: `${user?.firstName || ''} ${user?.lastName}`,
              ...user,
            }))}
            brokers={brokers?.map((user) => ({
              name: `${user?.firstName || ''} ${user?.lastName}`,
              ...user,
            }))}
            handleOnSubmit={(newData) => {
              setValues({
                ...values,
                ...newData,
              });
            }}
          />
        )}

        {isOpenContractInputFieldModal && (
          <ContractInputFieldModal
            isOpen={isOpenContractInputFieldModal}
            setIsOpen={setIsOpenContractInputFieldModal}
            editData={values}
            listings={listings}
            entities={entities}
            sellers={sellers?.map((user) => ({
              name: `${user?.firstName || ''} ${user?.lastName}`,
              ...user,
            }))}
            buyers={buyers?.map((user) => ({
              name: `${user?.firstName || ''} ${user?.lastName}`,
              ...user,
            }))}
            cooperatingBroker={cooperatingBroker?.map((user) => ({
              name: `${user?.firstName || ''} ${user?.lastName}`,
              ...user,
            }))}
            thirdPartyContacts={thirdPartyContacts?.map((user) => ({
              name: `${user?.firstName || ''} ${user?.lastName}`,
              ...user,
            }))}
            brokers={brokers?.map((user) => ({
              name: `${user?.firstName || ''} ${user?.lastName}`,
              ...user,
            }))}
            handleOnSubmit={(newData) => {
              setValues({
                ...values,
                ...newData,
              });
            }}
          />
        )}
      </SideBarSkeleton>
    </>
  );
};

export default AddContractAgreement;
