import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { MenuItem, TextField } from '@mui/material';

import { parseNoteBox } from '../../utils/functions';
import { apiHeader, BaseURL } from '../../config/apiUrl';
import { Delete, Get, Post } from '../../Axios/AxiosFunctions';
import Loader from '../../Component/Loader';
import NoData from '../../Component/NoData/NoData';
import { DropDown } from '../../Component/DropDown/DropDown';
import { Button } from '../../Component/Button/Button';
import AreYouSureModal from '../../modals/AreYouSureModal';
import BVIEditorModal from '../../modals/BVIEditorModal';
import classes from './BviViewer.module.css';

const FieldSection = ({ name, value }) => {
  return (
    <div>
      <div className={classes.headline}>{name}</div>
      <div className={classes.textContainer}>{value}</div>
    </div>
  );
};

const BviViewer = ({ listingId }) => {
  const { user, access_token: accessToken } = useSelector(
    (state) => state?.authReducer || {},
  );

  const [loading, setLoading] = useState(false);
  const [bvis, setBvis] = useState([]);
  const [selectedBvi, setSelectedBvi] = useState(null);
  const [parsedBvi, setParsedBvi] = useState([]);

  const [isEditing, setIsEditing] = useState(false);
  const [isOpenBVIEditor, setIsOpenBVIEditor] = useState('');
  const [showSureModal, setShowSureModal] = useState(false);

  const getBvis = async () => {
    const url = BaseURL(`business/${listingId}/admin/bvi`);

    setLoading(true);
    const response = await Get(url, accessToken);
    setLoading(false);

    if (response?.data) {
      let data = [];
      if (Array.isArray(response?.data?.noteBox)) {
        data = response?.data?.noteBox;
      } else if (response?.data?.noteBox && response?.data?.owner?.length) {
        data = [
          {
            seller: response?.data?.owner[0],
            bvi: response?.data?.noteBox,
          },
        ];
      }
      setBvis(data);
      if (data?.length && !selectedBvi) setSelectedBvi(data[0]);
    }
  };

  const updateBvi = async (seller, bvi) => {
    const url = BaseURL(`business/${listingId}/admin/bvi`);
    setIsEditing(true);
    const response = await Post(
      url,
      { seller: seller?._id, bvi },
      apiHeader(accessToken),
    );
    setIsEditing(false);
    if (response?.data) {
      toast.success(
        `BVI ${isOpenBVIEditor == 'create' ? 'saved' : 'updated'} successfully`,
      );
      setSelectedBvi({ seller, bvi });
      setIsOpenBVIEditor('');
      getBvis();
    }
  };

  const deleteBvi = async () => {
    if (!selectedBvi) return;
    const url = BaseURL(
      `business/${listingId}/admin/bvi/${selectedBvi?.seller?._id}`,
    );
    setIsEditing(true);
    const response = await Delete(url, null, apiHeader(accessToken));
    setIsEditing(false);
    if (response?.data) {
      toast.success('BVI deleted successfully');
      setIsOpenBVIEditor('');
      const nBvis = bvis.filter(
        (ele) => ele?.seller?._id != selectedBvi?.seller?._id,
      );
      setBvis(nBvis);
      nBvis?.length > 0 ? setSelectedBvi(nBvis[0]) : setSelectedBvi(null);
      setShowSureModal(false);
    }
  };

  useEffect(() => {
    getBvis();
  }, []);

  useEffect(() => {
    if (selectedBvi) {
      setParsedBvi(parseNoteBox(selectedBvi?.bvi));
    }
  }, [selectedBvi]);

  return (
    <>
      <div className={[classes.mainContainer]}>
        <div className={[classes.headerContainer]}>
          {selectedBvi && (
            <TextField
              name="seller"
              label="Seller"
              select
              sx={{
                width: '200px',
                textTransform: 'capitalize',
              }}
              value={selectedBvi?.seller?._id}
              onChange={(e) => {
                setSelectedBvi(
                  bvis.find((ele) => ele?.seller._id == e.target.value),
                );
              }}
            >
              {bvis.map((r) => (
                <MenuItem
                  key={r?.seller._id}
                  value={r?.seller._id}
                  sx={{ textTransform: 'capitalize' }}
                >
                  {`${r?.seller.firstName} ${r?.seller?.lastName}`}
                </MenuItem>
              ))}
            </TextField>
          )}
          <Button
            label={'Create'}
            onClick={() => {
              setIsOpenBVIEditor('create');
            }}
          />
          {selectedBvi && (
            <Button
              label={'Edit'}
              onClick={() => {
                setIsOpenBVIEditor('edit');
              }}
            />
          )}
          {selectedBvi && (
            <Button
              label={'Delete'}
              onClick={() => {
                setShowSureModal(true);
              }}
            />
          )}
        </div>
        {loading ? (
          <Loader />
        ) : !parsedBvi || !parsedBvi?.length ? (
          <NoData />
        ) : (
          <div>
            {parsedBvi?.map((field) => (
              <FieldSection name={field.name} value={field.value} />
            ))}
          </div>
        )}

        {isOpenBVIEditor && (
          <BVIEditorModal
            show={isOpenBVIEditor}
            setShow={setIsOpenBVIEditor}
            isSaving={isEditing}
            handleSubmit={updateBvi}
            title={isOpenBVIEditor == 'create' ? 'Create BVI' : 'Edit BVI'}
            data={isOpenBVIEditor == 'create' ? null : selectedBvi}
          />
        )}

        {showSureModal && (
          <AreYouSureModal
            isApiCall={isEditing}
            setShow={setShowSureModal}
            show={showSureModal}
            onClick={deleteBvi}
            subTitle={`Do you want to delete this BVI?`}
          />
        )}
      </div>
    </>
  );
};

export default BviViewer;
