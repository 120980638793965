import React, { useEffect, useRef, useState } from 'react';
import { Row } from 'react-bootstrap';
import { Button } from '../../Component/Button/Button';
import classes from './AddContractTemplate.module.css';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  CONTRACT_EDITOR_CONFIG,
  MSG_REQUIRED_FIELD,
} from '../../utils/contants';
import {
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  FormControl,
  InputLabel,
  InputAdornment,
  Typography,
} from '@mui/material';
import SearchIcon from '@material-ui/icons/Search';
import { useSelector } from 'react-redux';
import { apiHeader, BaseURL } from '../../config/apiUrl';
import { Patch, Post } from '../../Axios/AxiosFunctions';
import { toast } from 'react-toastify';
import PopupDialog from './PopupDialog';
import SideBarSkeleton from '../../Component/SideBarSkeleton';
import { MdContentCopy } from 'react-icons/md';
import { FiPlusCircle } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import JoditEditor from 'jodit-react';

const VARIABLES = [
  {
    id: 1,
    label: 'Company Logo',
    value: '*|company_logo|*',
  },
  {
    id: 2,
    label: 'Company Name',
    value: '*|company_name|*',
  },
  {
    id: 3,
    label: 'Company Address',
    value: '*|company_address|*',
  },
  {
    id: 11,
    label: 'Brokerage firm name',
    value: '*|listing_brokerage_name|*',
  },
  {
    id: 12,
    label: 'Brokerage firm address',
    value: '*|listing_brokerage_address|*',
  },
  {
    id: 13,
    label: 'Cooperating Brokerage Name',
    value: '*|cooperating_brokerage_name|*',
  },
  {
    id: 14,
    label: 'Cooperating Brokerage Address',
    value: '*|cooperating_brokerage_address|*',
  },
  {
    id: 15,
    label: 'Commission Percentage',
    value: '*|commission_percentage|*',
  },
  {
    id: 16,
    label: 'Commission Payment Terms',
    value: '*|commission_payment_terms|*',
  },
  {
    id: 17,
    label: 'Cooperating Broker Name',
    value: '*|cooperating_broker_name|*',
  },
  {
    id: 18,
    label: 'Cooperating Broker Title',
    value: '*|cooperating_broker_title|*',
  },
  {
    id: 19,
    label: 'Cooperating Broker Phone',
    value: '*|cooperating_broker_phone|*',
  },
  {
    id: 20,
    label: 'Cooperating Broker Email',
    value: '*|cooperating_broker_email|*',
  },
  {
    id: 21,
    label: 'Listing Broker Name',
    value: '*|listing_broker_name|*',
  },
  {
    id: 22,
    label: 'Listing Broker Title',
    value: '*|listing_broker_title|*',
  },
  {
    id: 23,
    label: 'Listing Broker Phone',
    value: '*|listing_broker_phone|*',
  },
  {
    id: 24,
    label: 'Listing Broker Email',
    value: '*|listing_broker_email|*',
  },
  {
    id: 54,
    label: 'Listing Broker Telephone',
    value: '*|listing_broker_telephone|*',
  },
  {
    id: 56,
    label: 'Listing Broker Signature Date',
    value: '*|listing_broker_signature_date|*',
  },
  {
    id: 25,
    label: 'Agreement Date',
    value: '*|Agreement_Date|*',
  },
  {
    id: 26,
    label: 'Closing Date',
    value: '*|closing_date|*',
  },
  {
    id: 27,
    label: 'Agreement Title',
    value: '*|Agreement_Title|*',
  },
  {
    id: 28,
    label: 'Buyer Name',
    value: '*|Buyer_Name|*',
  },
  {
    id: 29,
    label: 'Buyer Email',
    value: '*|buyer_email|*',
  },
  {
    id: 30,
    label: 'Buyer Address',
    value: '*|buyer_address|*',
  },
  {
    id: 31,
    label: 'Buyer Phone',
    value: '*|buyer_phone|*',
  },
  {
    id: 32,
    label: 'Seller',
    value: [
      {
        id: 4,
        label: 'Seller Entity Name',
        value: '*|seller_entity_name|*',
      },
      {
        id: 5,
        label: 'Seller Entity Street',
        value: '*|seller_entity_street|*',
      },
      {
        id: 6,
        label: 'Seller Entity City',
        value: '*|seller_entity_city|*',
      },
      {
        id: 7,
        label: 'Seller Entity State',
        value: '*|seller_entity_state|*',
      },
      {
        id: 8,
        label: 'Seller Entity Zipcode',
        value: '*|seller_entity_zip|*',
      },
      {
        id: 9,
        label: 'Seller Entity Country',
        value: '*|seller_entity_country|*',
      },
      {
        id: 10,
        label: 'Seller Entity Phone',
        value: '*|seller_entity_phone|*',
      },
      {
        id: 32,
        label: 'Seller Name',
        value: '*|Seller_Name|*',
      },
      {
        id: 33,
        label: 'Seller Email',
        value: '*|seller_email|*',
      },
      {
        id: 34,
        label: 'Seller Address',
        value: '*|seller_address|*',
      },
      {
        id: 35,
        label: 'Seller Phone',
        value: '*|seller_phone|*',
      },
      {
        id: 44,
        label: 'Seller Title',
        value: '*|seller_title|*',
      },
      {
        id: 45,
        label: 'Seller Signature Date',
        value: '*|seller_signature_date|*',
      },
      {
        id: 79,
        label: 'Seller Carry Years',
        value: '*|seller_carry_years|*',
      },
      {
        id: 80,
        label: 'Seller Carry Rate',
        value: '*|seller_carry_rate|*',
      },
      {
        id: 51,
        label: 'Seller Execution Date',
        value: '*|seller_execution_date|*',
      },
    ],
  },
  {
    id: 36,
    label: 'Business Name',
    value: '*|Business_Name|*',
  },
  {
    id: 37,
    label: 'Business Address',
    value: '*|Business_Address|*',
  },
  {
    id: 38,
    label: 'Termination Reason',
    value: '*|Termination_Reason|*',
  },
  {
    id: 39,
    label: 'Clause Reference',
    value: '*|Clause_Reference|*',
  },
  {
    id: 40,
    label: 'Due Diligence Deadline',
    value: '*|Due_Diligence_Deadline|*',
  },
  {
    id: 41,
    label: 'Document List',
    value: '*|Document_List|*',
  },
  {
    id: 42,
    label: 'Buyer Title',
    value: '*|buyer_title|*',
  },
  {
    id: 43,
    label: 'Buyer Signature Date',
    value: '*|buyer_signature_date|*',
  },
  {
    id: 46,
    label: 'Earnest Money Amount',
    value: '*|earnest_money_amount|*',
  },
  {
    id: 47,
    label: 'Escrow Agent Name',
    value: '*|escrow_agent_name|*',
  },
  {
    id: 48,
    label: 'Escrow Company Name',
    value: '*|escrow_company_name|*',
  },
  {
    id: 49,
    label: 'Buyer Execution Date 1',
    value: '*|buyer_execution_date_1|*',
  },
  {
    id: 50,
    label: 'Buyer Execution Date 2',
    value: '*|buyer_execution_date_2|*',
  },
  {
    id: 52,
    label: 'Date Executed',
    value: '*|date_executed|*',
  },
  {
    id: 53,
    label: 'Today Date',
    value: '*|today_date|*',
  },
  {
    id: 57,
    label: 'Document Date',
    value: '*|document_date|*',
  },
  {
    id: 60,
    label: 'Broker Provided Info',
    value: '*|broker_provided_info|*',
  },
  {
    id: 61,
    label: 'Broker Firm Name',
    value: '*|broker_firm_name|*',
  },
  {
    id: 62,
    label: 'Broker Name',
    value: '*|broker_name|*',
  },
  {
    id: 63,
    label: 'Purchase Price',
    value: '*|purchase_price|*',
  },
  {
    id: 64,
    label: 'Down Payment',
    value: '*|down_payment|*',
  },
  {
    id: 65,
    label: 'Loan At Closing',
    value: '*|loan_at_closing|*',
  },
  {
    id: 66,
    label: 'Note At Closing',
    value: '*|note_at_closing|*',
  },
  {
    id: 67,
    label: 'Start Date',
    value: '*|start_date|*',
  },
  {
    id: 68,
    label: 'End Date',
    value: '*|end_date|*',
  },
  {
    id: 69,
    label: 'Sale Commission Amount',
    value: '*|sale_commission_amount|*',
  },
  {
    id: 70,
    label: 'Sale Commission Percentage',
    value: '*|sale_commission_percentage|*',
  },
  {
    id: 71,
    label: 'RE Commission Amount',
    value: '*|RE_commission_amount|*',
  },
  {
    id: 72,
    label: 'RE Commission Percentage',
    value: '*|RE_commission_percentage|*',
  },
  {
    id: 73,
    label: 'Equipment Price',
    value: '*|equipment_price|*',
  },
  {
    id: 74,
    label: 'Inventory Price',
    value: '*|inventory_price|*',
  },
  {
    id: 75,
    label: 'Term Cash',
    value: '*|term_cash|*',
  },
  {
    id: 76,
    label: 'Term sba',
    value: '*|term_sba|*',
  },
  {
    id: 77,
    label: 'Term Conventional',
    value: '*|term_conventional|*',
  },
  {
    id: 78,
    label: 'Term Other',
    value: '*|term_other|*',
  },

  {
    id: 81,
    label: 'Acceptance Deadline',
    value: '*|acceptance_deadline|*',
  },
  {
    id: 82,
    label: 'Inventory Margin',
    value: '*|inventory_margin|*',
  },
  {
    id: 83,
    label: 'Buyer Entity Name',
    value: '*|buyer_entity_name|*',
  },
];

const TRIGGER = [
  { value: 'CONTRACTS', label: 'Contracts' },
  { value: 'AMENDMENTS', label: 'Amendments' },
];

const validationSchema = yup.object().shape({
  type: yup.string().required(MSG_REQUIRED_FIELD),
  name: yup.string().required(MSG_REQUIRED_FIELD),
  content: yup.string().required(MSG_REQUIRED_FIELD),
});

const AddContractTemplate = ({ editData }) => {
  const editorRef = useRef();

  const accessToken = useSelector((state) => state?.authReducer?.access_token);
  const baseApiUrl = BaseURL(`contract-template`);

  const navigate = useNavigate();

  const [variables, setVariables] = useState(VARIABLES);
  const [showPopup, setShowPopup] = useState(false);
  const [popuptitle, setPopupTitle] = useState('');
  const [popupVariables, setPopupVariables] = useState([]);

  const {
    errors,
    values,
    handleChange,
    touched,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    setSubmitting,
    resetForm,
    handleBlur,
  } = useFormik({
    initialValues: {
      type: editData?.type || '',
      name: editData?.name || '',
      content: editData?.content || '',
      isEnabled: editData?.isEnabled,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async ({ type, name, content }) => {
      const body = {
        type,
        name,
        content,
        isEnabled: true,
      };

      setSubmitting(true);

      if (editData?._id) {
        const response = await Patch(
          `${baseApiUrl}/${editData?._id}`,
          body,
          apiHeader(accessToken),
        );

        if (response !== undefined) {
          toast.success(`Template Edited Successfully.`);
          setSubmitting(false);
          navigate('/contract-templates');
        }
      } else {
        const response = await Post(baseApiUrl, body, apiHeader(accessToken));

        if (response !== undefined) {
          toast.success(`Template Created Successfully.`);
          setSubmitting(false);
          navigate('/contract-templates');
          resetForm();
        }
      }
    },
  });

  const copyText = async (text) => {
    try {
      await navigator.clipboard.writeText(text);

      toast.success('Copied successfully');
    } catch (error) {
      console.error('Failed to copy text: ', error);
    }
  };

  const addText = (text) => {
    const editorInstance = editorRef?.current;

    if (editorInstance) {
      const { selection } = editorInstance;

      if (selection) {
        const { range } = selection;
        if (range) {
          selection?.insertHTML(text);
          const newRange = selection.range;
          selection.selectRange(newRange);
          toast.success('Field added successfully');
        }
      }
    }
  };

  const onPopup = (title) => {
    setPopupTitle(title);
    setPopupVariables(
      VARIABLES.find((variable) => variable.label === title).value,
    );
    setShowPopup(true);
  };

  useEffect(() => {
    if (editorRef?.current?.value) {
      setFieldValue('content', editorRef?.current?.value);
    } else {
      setFieldValue('content', '');
    }
  }, [editorRef?.current?.value]);

  return (
    <>
      <SideBarSkeleton>
        <div className={classes.container_main}>
          <div className={classes.main_heading}>
            <h4>{editData?._id ? 'Edit' : 'Add'} Contract Template</h4>
          </div>

          <form
            onSubmit={handleSubmit}
            className={classes.addEmailTemplateModal_main}
          >
            <Row className={classes.input_row}>
              <FormControl
                fullWidth
                error={touched.type && Boolean(errors.type)}
              >
                <InputLabel id="type-label">Template Type *</InputLabel>
                <Select
                  labelId="type-label"
                  name="type"
                  value={values.type}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Template Type *"
                >
                  {TRIGGER.map((trigger) => (
                    <MenuItem key={trigger.value} value={trigger.value}>
                      {trigger.label}
                    </MenuItem>
                  ))}
                </Select>

                {touched.type && errors.type && (
                  <FormHelperText>{errors.type}</FormHelperText>
                )}
              </FormControl>

              <TextField
                label="Name *"
                name="name"
                onChange={handleChange}
                value={values.name}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
              />

              <div className={classes.var_section}>
                <TextField
                  label="Search *"
                  onChange={(e) => {
                    const value = e?.target?.value?.toLowerCase()?.trim();
                    if (value) {
                      setVariables(
                        VARIABLES?.filter(
                          (item) =>
                            item?.label?.toLowerCase()?.includes(value) ||
                            item?.value?.toLowerCase()?.includes(value),
                        ),
                      );
                    } else {
                      setVariables(VARIABLES);
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />

                <div className={classes?.variables}>
                  {variables &&
                    variables?.length > 0 &&
                    variables.map((variable, index) =>
                      Array.isArray(variable.value) ? (
                        <div
                          className={classes.var_item_folder}
                          key={index}
                          onClick={() => onPopup(variable.label)}
                        >
                          {variable.label}
                        </div>
                      ) : (
                        <div className={classes.var_item} key={index}>
                          {variable.label} ({variable.value})
                          <div className={classes.action_button}>
                            <button
                              type="button"
                              onClick={() => addText(variable.value)}
                              style={{ opacity: 0.8 }}
                            >
                              <FiPlusCircle size={20} />
                            </button>

                            <button
                              type="button"
                              onClick={() => copyText(variable.value)}
                            >
                              <MdContentCopy size={18} />
                            </button>
                          </div>
                        </div>
                      ),
                    )}

                  {variables && !variables?.length && (
                    <Typography textAlign={'center'}>No Data Found</Typography>
                  )}
                </div>
                <PopupDialog
                  open={showPopup}
                  setOpen={setShowPopup}
                  popupTitle={popuptitle}
                  popupVariables={popupVariables}
                  copyText={copyText}
                />
              </div>

              <div className={classes.textEditor}>
                <JoditEditor
                  ref={editorRef}
                  value={values?.content}
                  config={{
                    ...CONTRACT_EDITOR_CONFIG,
                    events: {
                      afterInit: (e) => {
                        editorRef.current = e;
                      },
                    },
                  }}
                />
              </div>
            </Row>

            <div className={classes.btn_main}>
              <Button
                type="submit"
                className={classes.btn}
                label={
                  isSubmitting
                    ? 'Submitting...'
                    : editData?._id
                      ? 'Edit Template'
                      : 'Add Template'
                }
                disabled={isSubmitting}
              />
            </div>
          </form>
        </div>
      </SideBarSkeleton>
    </>
  );
};

export default AddContractTemplate;
