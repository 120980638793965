import React, { useRef, useState } from 'react';
import { Row } from 'react-bootstrap';
import { Button } from '../../Component/Button/Button';
import classes from './AddAutomatedEmail.module.css';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  AUTOMATED_EMAIL_EDITOR_CONFIG,
  MSG_REQUIRED_FIELD,
} from '../../utils/contants';
import {
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  FormControl,
  InputLabel,
} from '@mui/material';
import { Checkbox } from '../../Component/Checkbox/Checkbox';
import { useSelector } from 'react-redux';
import { apiHeader, BaseURL } from '../../config/apiUrl';
import { Patch, Post } from '../../Axios/AxiosFunctions';
import { toast } from 'react-toastify';
import SideBarSkeleton from '../../Component/SideBarSkeleton';
import { MdContentCopy } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import JoditEditor from 'jodit-react';

const VARIABLES = [
  {
    id: 1,
    label: 'For User Name',
    value: '*|user-name|*',
  },
  {
    id: 2,
    label: 'For User Email',
    value: '*|user-email|*',
  },
  {
    id: 3,
    label: 'For Listing Company Name',
    value: '*|listing-company-name|*',
  },
  {
    id: 4,
    label: 'For Listing Headline',
    value: '*|listing-headline|*',
  },
  {
    id: 5,
    label: 'For Broker Name',
    value: '*|broker-name|*',
  },
  {
    id: 6,
    label: 'For Password Reset link',
    value: '*|pwd-reset|*',
  },
  {
    id: 7,
    label: 'For Account Activation link',
    value: '*|activate-account-link|*',
  },
  {
    id: 8,
    label: 'For Broker Email',
    value: '*|broker-email|*',
  },
  {
    id: 9,
    label: 'For Broker Phone',
    value: '*|broker-phone|*',
  },
  {
    id: 10,
    label: 'Project Name',
    value: '*|project-name*|',
  },
  {
    id: 11,
    label: 'Creator',
    value: '*|creator|*',
  },
  {
    id: 12,
    label: 'Create Date',
    value: '*|create-date|*',
  },
  {
    id: 13,
    label: 'Due Date',
    value: '*|due-date|*',
  },
  {
    id: 14,
    label: 'Set up password',
    value: '*|set-up-password|*',
  },
  {
    id: 15,
    label: 'Sign NDA',
    value: '*|sign-NDA|*',
  },
  {
    id: 16,
    label: 'Insert Link',
    value: '*|insert-link|*',
  },
  {
    id: 17,
    label: 'Client Name',
    value: '*|client-name|*',
  },
  {
    id: 18,
    label: 'Login Link',
    value: '*|login-link|*',
  },
  {
    id: 19,
    label: 'Seller Name',
    value: '*|seller-name|*',
  },
  {
    id: 20,
    label: 'Seller Email',
    value: '*|seller-email|*',
  },
  {
    id: 21,
    label: 'Listing Front Link',
    value: '*|listing-front-link|*',
  },
  {
    id: 22,
    label: 'User Name Assigned Project',
    value: '*|user-name-assigned-project|*',
  },
  {
    id: 23,
    label: 'Task assigned to',
    value: '*|task-assigned-to|*',
  },
  {
    id: 24,
    label: 'Task due date',
    value: '*|task-due-date|*',
  },
  {
    id: 25,
    label: 'Task create date',
    value: '*|task-create-date|*',
  },
  {
    id: 26,
    label: 'Task creator',
    value: '*|task-creator|*',
  },
  {
    id: 27,
    label: 'Task assignee',
    value: '*|task-assignee|*',
  },
  {
    id: 28,
    label: 'NDA download link',
    value: '*|nda-download-link|*',
  },
  {
    id: 29,
    label: 'To Do Create Date',
    value: '*|to-do-create-date|*',
  },
  {
    id: 30,
    label: 'To Do Due Date',
    value: '*|to-do-due-date|*',
  },
  {
    id: 31,
    label: 'To Do Assignee',
    value: '*|to-do-assignee|*',
  },
  {
    id: 32,
    label: 'To Do Creator',
    value: '*|to-do-creator|*',
  },
  {
    id: 33,
    label: 'To Do Creator',
    value: '*|to-do-creator|*',
  },
  {
    id: 34,
    label: 'BVI Submitted',
    value: '*|bvi-submitted|*',
  },
  {
    id: 35,
    label: 'Files Uploaded',
    value: '*|files-uploaded|*',
  },
  {
    id: 36,
    label: 'Internal User Account Activate Link',
    value: '*|internal-user-acct-activate-link|*',
  },
  {
    id: 37,
    label: 'Internal User Account Status Change',
    value: '*|internal-user-account-status-change|*',
  },
  {
    id: 38,
    label: 'User Account Activated',
    value: '*|user-account-activated|*',
  },
  {
    id: 39,
    label: 'Internal User Email',
    value: '*|internal-user-email|*',
  },
  {
    id: 40,
    label: 'Internal User Name',
    value: '*|internal-user-name|*',
  },
  {
    id: 41,
    label: 'Internal User Password Reset Link',
    value: '*|internal-user-password-reset-link|*',
  },
  {
    id: 42,
    label: 'User requested password reset',
    value: '*|user-password-reset-link|*',
  },
  {
    id: 43,
    label: 'User updated email',
    value: '*|change-email-link|*',
  },
  {
    id: 44,
    label: 'Current User Email',
    value: '*|user-logged-in|*',
  },
  {
    id: 45,
    label: 'Signature',
    value: '*|signature|*',
  },
  {
    id: 46,
    label: 'BVI Sender',
    value: '*|bvi-sender|*',
  },
  {
    id: 47,
    label: 'BVI Sender Name',
    value: '*|bvi-sender-name|*',
  },
  {
    id: 48,
    label: 'Assigned User Of Data Room',
    value: '*|user-assigned-to-data-room|*',
  },
];

const TRIGGER = [
  { value: 'activeUser', label: 'New User Created' },
  {
    value: 'taskTemplate',
    label: 'Email when task is created (for all participants)',
  },
  {
    value: 'bizbuysellNewUser',
    label: 'Bizbuysell Form Filled [New Customer]',
  },
  {
    value: 'bizbuysellExistingUser',
    label: 'Bizbuysell Form Filled [Existing Customer]',
  },
  { value: 'buyerNdaSignAuto', label: 'Buyer signed NDA [Listing automated]' },
  {
    value: 'buyerNdaSubmitUnauto',
    label: 'Buyer signed NDA [Listing not automated]',
  },
  {
    value: 'projectTemplate',
    label: 'Email to participants when new project is created',
  },
  {
    value: 'brokerNdaSignAuto',
    label: 'Email to Broker when NDA is signed [Listing Automated]',
  },
  {
    value: 'brokerNdaSubmitUnauto',
    label: 'Email to Broker when NDA is signed [Listing NOT Automated]',
  },
  {
    value: 'roomBuyerTemplate',
    label: 'Email to buyer when data room is assigned',
  },
  { value: 'BVIassignedMail', label: 'BVI Assigned' },
  { value: 'roomBuyerTemplate', label: 'Data room assigned to buyer' },
  { value: 'roomSellerTemplate', label: 'Data room assigned to seller' },
  { value: 'room3rdPartyTemplate', label: '3rd party data room assigned' },
  { value: 'uploadTemplate', label: 'Files uploaded to data room' },
  { value: 'ToDoAssigned', label: 'To-do assigned' },
  { value: 'UserActivatedAccount', label: 'User activated' },
  { value: 'ListingStatusChange', label: 'Listing status change' },
  {
    value: 'InternalUserAdded',
    label: 'New internal user added [admin/broker]',
  },
  { value: 'InternalUserActivated', label: 'Internal user activated' },
  {
    value: 'InternalPasswordReset',
    label: 'Internal User requested password reset',
  },
  { value: 'passwordReset', label: 'User requested password reset' },
  { value: 'changeEmail', label: 'User updated email' },
  { value: 'interestAdded', label: 'Interest Added' },
  { value: 'VIPUserAdded', label: 'VIP User Added' },
  { value: 'submitBVI', label: 'Seller Submit BVI' },
  { value: 'ndaApproved', label: 'NDA Approved' },
  { value: 'uploadByBrokerAdmin', label: 'Upload By Broker Admin' },
  { value: 'uploadByUser', label: 'Upload By User' },
];

const validationSchema = yup.object().shape({
  trigger: yup.string().required(MSG_REQUIRED_FIELD),
  from: yup.string().required(MSG_REQUIRED_FIELD),
  to: yup.string().required(MSG_REQUIRED_FIELD),
  // cc: yup.string().required(MSG_REQUIRED_FIELD),
  subject: yup.string().required(MSG_REQUIRED_FIELD),
  // link: yup.string().required(MSG_REQUIRED_FIELD),
});

const AddAutomatedEmail = ({ editData }) => {
  const editorRef = useRef();

  const { access_token: accessToken, company_id: companyId } = useSelector(
    (state) => state?.authReducer,
  );
  const baseApiUrl = BaseURL(`automated-email-template`);

  const [emailBodyState, setEmailBodyState] = useState(
    editData?.emailBody || '',
  );

  const navigate = useNavigate();

  const {
    errors,
    values,
    handleChange,
    touched,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    setSubmitting,
    resetForm,
    handleBlur,
  } = useFormik({
    initialValues: {
      trigger: editData?.trigger || '',
      from: editData?.from || '',
      to: editData?.to || '',
      cc: editData?.cc || '',
      subject: editData?.subject || '',
      isEnabled: editData?.isEnabled || true,
      link: editData?.link || '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (formData) => {
      const body = {
        ...formData,
        emailBody: emailBodyState,
      };

      setSubmitting(true);

      if (editData?._id) {
        const response = await Patch(
          `${baseApiUrl}/${editData?._id}`,
          body,
          apiHeader(accessToken),
        );

        if (response !== undefined) {
          toast.success(`Template Edited Successfully.`);
          setSubmitting(false);
          navigate('/automated-emails');
        }
      } else {
        const response = await Post(
          baseApiUrl,
          body,
          apiHeader(accessToken),
          companyId,
        );

        if (response !== undefined) {
          toast.success(`Template Created Successfully.`);
          setSubmitting(false);
          setEmailBodyState('');
          navigate('/automated-emails');
          resetForm();
        }
      }
    },
  });

  const copyText = async (text) => {
    try {
      await navigator.clipboard.writeText(text);

      toast.success('Link Copied');
    } catch (error) {
      console.error('Failed to copy text: ', error);
    }
  };

  return (
    <>
      <SideBarSkeleton>
        <div className={classes.container_main}>
          <div className={classes.main_heading}>
            <h4>{editData?._id ? 'Edit' : 'Add'} Automated Email</h4>
          </div>

          <form
            onSubmit={handleSubmit}
            className={classes.addEmailTemplateModal_main}
          >
            <Row className={classes.input_row}>
              <FormControl
                fullWidth
                error={touched.trigger && Boolean(errors.trigger)}
              >
                <InputLabel id="trigger-label">Trigger *</InputLabel>
                <Select
                  labelId="trigger-label"
                  name="trigger"
                  value={values.trigger}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Trigger *"
                >
                  {TRIGGER.map((trigger) => (
                    <MenuItem key={trigger.value} value={trigger.value}>
                      {trigger.label}
                    </MenuItem>
                  ))}
                </Select>
                {touched.trigger && errors.trigger && (
                  <FormHelperText>{errors.trigger}</FormHelperText>
                )}
              </FormControl>

              <TextField
                label="From email *"
                name="from"
                onChange={handleChange}
                value={values.from}
                error={touched.from && Boolean(errors.from)}
                helperText={touched.from && errors.from}
              />

              <TextField
                label="To email *"
                name="to"
                onChange={handleChange}
                value={values.to}
                error={touched.to && Boolean(errors.to)}
                helperText={touched.to && errors.to}
              />

              <TextField
                label="CC"
                name="cc"
                onChange={handleChange}
                value={values.cc}
                error={touched.cc && Boolean(errors.cc)}
                helperText={touched.cc && errors.cc}
              />

              <TextField
                label="Subject *"
                name="subject"
                onChange={handleChange}
                value={values.subject}
                error={touched.subject && Boolean(errors.subject)}
                helperText={touched.subject && errors.subject}
                className={classes.subject_input}
              />

              <div className={classes.var_section}>
                {VARIABLES.map((variable, index) => (
                  <div className={classes.var_item} key={index}>
                    {variable.label} ({variable.value})
                    <div onClick={() => copyText(variable.value)}>
                      <MdContentCopy />
                    </div>
                  </div>
                ))}
              </div>

              <div className={classes.textEditor}>
                <JoditEditor
                  ref={editorRef}
                  value={emailBodyState}
                  config={{
                    ...AUTOMATED_EMAIL_EDITOR_CONFIG,
                    events: {
                      afterInit: (e) => {
                        editorRef.current = e;
                      },
                    },
                  }}
                  onBlur={(value) => setEmailBodyState(value)}
                />
              </div>

              <TextField
                label="Link"
                name="link"
                onChange={handleChange}
                value={values.link}
                error={touched.link && Boolean(errors.link)}
                helperText={touched.link && errors.link}
              />

              <Checkbox
                setValue={(e) => setFieldValue('isEnabled', e === 'Active')}
                value={values.isEnabled ? 'Active' : ''}
                label={'Active'}
              />
            </Row>

            <div className={classes.btn_main}>
              <Button
                type="submit"
                className={classes.btn}
                label={
                  isSubmitting
                    ? 'Submitting...'
                    : editData?._id
                      ? 'Edit Template'
                      : 'Add Template'
                }
                disabled={isSubmitting}
              />
            </div>
          </form>
        </div>
      </SideBarSkeleton>
    </>
  );
};

export default AddAutomatedEmail;
