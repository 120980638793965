import React from 'react';
import { useFormik } from 'formik';
import { Row } from 'react-bootstrap';

import { moment } from '../../utils/timeUtils';
import ModalSkeleton from '../ModalSkeleton';
import { Button } from '../../Component/Button/Button';
import { Input } from '../../Component/Input/Input';
import { TextArea } from '../../Component/TextArea';
import { DropDown } from '../../Component/DropDown/DropDown';
import classes from './ContractInputFieldModal.module.css';

const NUMBER_REGEX = /^[0-9\b]+$/;
const FLOAT_REGEX = /[-+]?\d*[.,]\d+|\d+/;

const ContractInputFieldModal = ({
  isOpen,
  setIsOpen,
  editData,
  listings = [],
  entities = [],
  sellers = [],
  buyers = [],
  cooperatingBroker = [],
  thirdPartyContacts = [],
  brokers = [],
  handleOnSubmit,
}) => {
  const { values, handleSubmit, setFieldValue, isSubmitting } = useFormik({
    initialValues: {
      // for template
      seller_carry_years: editData?.seller_carry_years || 0,
      seller_carry_rate: editData?.seller_carry_rate || 0,
      acceptance_deadline: editData?.acceptance_deadline || moment().format(),
      inventory_margin: editData?.inventory_margin || 0,

      // Listing
      listing: editData?.listing,

      // Entity
      entity: editData?.entity,
      entity_street: editData?.entity_street,
      entity_city: editData?.entity_city,
      entity_state: editData?.entity_state,
      entity_country: editData?.entity_country,
      entity_zip: editData?.entity_zip,
      entity_phone: editData?.entity_phone,

      listing_brokerage_name: editData?.listing_brokerage_name || '',
      listing_brokerage_address: editData?.listing_brokerage_address || '',

      business_name: editData?.business_name || '',
      business_address: editData?.business_address || '',

      company_name: editData?.company_name || '',
      company_address: editData?.company_address || '',

      // Buyer
      buyer: editData?.buyer,
      buyer_email: editData?.buyer_email,
      buyer_address: editData?.buyer_address,
      buyer_phone: editData?.buyer_phone,
      buyer_title: editData?.buyer_title,
      buyer_signature_date: editData?.buyer_signature_date,

      // Seller
      seller: editData?.seller,
      seller_email: editData?.seller_email,
      seller_address: editData?.seller_address,
      seller_phone: editData?.seller_phone,
      seller_title: editData?.seller_title,
      seller_signature_date: editData?.seller_signature_date,

      recipients: editData?.recipients || [],
      // broker: editData?.// broker||'',

      broker: editData?.broker || '',
      broker_firm_name: editData?.broker_firm_name || '',
      broker_provided_info: editData?.broker_provided_info || '',

      cooperating_brokerage_name: editData?.cooperating_brokerage_name || '',
      cooperating_brokerage_address:
        editData?.cooperating_brokerage_address || '',
      commission_percentage: editData?.commission_percentage || '',
      commission_payment_terms: editData?.commission_payment_terms || '',

      //Cooperating Broker
      cooperating_broker_name: editData?.cooperating_broker_name || '',
      cooperating_broker_title: editData?.cooperating_broker_title || '',
      cooperating_broker_phone: editData?.cooperating_broker_phone || '',
      cooperating_broker_email: editData?.cooperating_broker_email || '',

      // Agreement
      agreement_title: editData?.agreement_title || '',
      agreement_date: editData?.agreement_date || null,
      closing_date: editData?.closing_date || null,
      document_date: editData?.document_date || null,

      //
      termination_reason: editData?.termination_reason || '',
      clause_reference: editData?.clause_reference || '',
      due_diligence_deadline: editData?.due_diligence_deadline || '',
      document_list: editData?.document_list || '',

      earnest_money_amount: editData?.earnest_money_amount || '',
      escrow_agent_name: editData?.escrow_agent_name || '',
      escrow_company_name: editData?.escrow_company_name || '',

      today_date: editData?.today_date || null,
      buyer_execution_date_1: editData?.buyer_execution_date_1 || null,
      buyer_execution_date_2: editData?.buyer_execution_date_2 || null,
      seller_execution_date: editData?.seller_execution_date || null,
      date_executed: editData?.date_executed || null,

      purchase_price: editData?.purchase_price || '',
      down_payment: editData?.down_payment || '',
      loan_at_closing: editData?.loan_at_closing || '',
      note_at_closing: editData?.note_at_closing || '',
      start_date: editData?.start_date || null,
      end_date: editData?.end_date || null,
      sale_commission_amount: editData?.sale_commission_amount || '',
      sale_commission_percentage: editData?.sale_commission_percentage || '',
      RE_commission_amount: editData?.RE_commission_amount || '',
      RE_commission_percentage: editData?.RE_commission_percentage || '',
      equipment_price: editData?.equipment_price || '',
      inventory_price: editData?.inventory_price || '',
      term_cash: editData?.term_cash || '',
      term_sba: editData?.term_sba || '',
      term_conventional: editData?.term_conventional || '',
      term_other: editData?.term_other || '',

      // Listing Broker
      listing_broker_name: editData?.listing_broker_name || '',
      listing_broker_email: editData?.listing_broker_email || '',
      listing_broker_phone: editData?.listing_broker_phone || '',
      listing_broker_title: editData?.listing_broker_title || '',
      listing_broker_telephone: editData?.listing_broker_telephone || '',
      listing_broker_signature_date:
        editData?.listing_broker_signature_date || '',
    },
    onSubmit: async (value, { setSubmitting }) => {
      setSubmitting(true);
      handleOnSubmit(value);
      setIsOpen(false);
      setSubmitting(false);
    },
  });

  const isShowInputField = (name) => {
    return editData?.content?.includes(name);
  };

  return (
    <ModalSkeleton
      show={isOpen}
      setShow={setIsOpen}
      borderRadius="20px"
      width="1000px"
      borderLine={false}
      header={'Select Contract Field'}
      showCloseIcon={true}
    >
      <form
        onSubmit={handleSubmit}
        className={classes.addEmailTemplateModal_main}
      >
        <Row className={classes.input_row}>
          <DropDown
            setter={(item) => {
              setFieldValue('listing', item);
              setFieldValue('business_name', item?.companyName || '');
              setFieldValue('commission_percentage', item?.percentage || '');

              // setFieldValue('company_name', item?.company?.name || '');
              // setFieldValue('company_address', item?.company?.address || '');
              setFieldValue('company_name', item?.companyName || '');
              setFieldValue(
                'company_address',
                item?.businessAddress?.[0] || '',
              );

              if (item?.businessAddress?.length) {
                setFieldValue('business_address', item?.businessAddress?.[0]);
              }

              const entity = entities?.find((en) => en?._id === item?.entity);

              if (entity) {
                setFieldValue('entity', entity);
              }

              item?.owner?.forEach((s) => {
                const seller = sellers?.find((en) => en?._id === s?._id);

                if (seller) {
                  setFieldValue('seller', seller);
                  setFieldValue('seller_email', seller?.email);
                  setFieldValue('seller_phone', seller?.contact);

                  const signOrderData = values?.recipients?.filter(
                    (user) => user?.role !== 'Seller',
                  );

                  signOrderData.push({
                    send_email: true,
                    send_email_delay: 0,
                    id: seller?._id,
                    name: seller?.name,
                    email: seller?.email,
                    subject: 'Contract Agreement for Signature by SignWell',
                    message: 'Hello, Please review agreement and do signature',
                    role: 'Seller',
                  });

                  setFieldValue('recipients', signOrderData);
                }
              });

              item?.buyer?.forEach((id) => {
                const buyer = buyers?.find((bu) => bu?._id === id);

                if (buyer) {
                  setFieldValue('buyer', buyer);
                  setFieldValue('buyer_email', buyer?.email);
                  setFieldValue('buyer_phone', buyer?.contact);

                  const signOrderData = values?.recipients?.filter(
                    (user) => user?.role !== 'Buyer',
                  );

                  signOrderData.push({
                    send_email: true,
                    send_email_delay: 0,
                    id: buyer?._id,
                    name: buyer?.name,
                    email: buyer?.email,
                    subject: 'Contract Agreement for Signature by SignWell',
                    message: 'Hello, Please review agreement and do signature',
                    role: 'Buyer',
                  });

                  setFieldValue('recipients', signOrderData);
                }
              });

              if (entity) {
                setFieldValue('entity', entity);
              }

              const broker = brokers?.find(
                ({ _id }) => _id === item?.broker[0],
              );

              setFieldValue('listing_broker_name', broker || '');
              setFieldValue('listing_broker_email', broker?.email || '');
              setFieldValue('listing_broker_phone', broker?.contact || '');
            }}
            value={values?.listing}
            placeholder={'Choose Listing'}
            options={listings}
            optionLabel={'companyName'}
            optionValue={'_id'}
            label={'Listing'}
          />

          <DropDown
            setter={(item) => {
              setFieldValue('entity', item);

              setFieldValue('entity_street', item?.street);
              setFieldValue('entity_city', item?.city);
              setFieldValue('entity_state', item?.state);
              setFieldValue('entity_country', item?.country);
              setFieldValue('entity_zip', item?.zipcode);
              setFieldValue('entity_phone', item?.phone);
            }}
            value={values?.entity}
            placeholder={'Choose Entity'}
            options={entities}
            optionLabel={'name'}
            optionValue={'_id'}
            label={'Entity'}
          />

          {isShowInputField('*|seller_carry_years|*') && (
            <Input
              value={values?.seller_carry_years}
              setter={(e) => {
                setFieldValue('seller_carry_years', e);
              }}
              type={'number'}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
                width: '100%',
              }}
              label={'Seller Carry Years'}
            />
          )}

          {isShowInputField('*|seller_carry_rate|*') && (
            <Input
              value={values?.seller_carry_rate}
              setter={(e) => {
                setFieldValue('seller_carry_rate', e);
              }}
              type={'number'}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
                width: '100%',
              }}
              label={'Seller Carry Rate'}
            />
          )}

          {isShowInputField('*|acceptance_deadline|*') && (
            <Input
              value={moment(values?.acceptance_deadline).format('YYYY-MM-DD')}
              setter={(e) => {
                setFieldValue('acceptance_deadline', e);
              }}
              type={'date'}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
                width: '100%',
              }}
              label={'Acceptance Deadline'}
            />
          )}

          {isShowInputField('*|inventory_margin|*') && (
            <Input
              value={values?.inventory_margin}
              setter={(e) => {
                setFieldValue('inventory_margin', e);
              }}
              type={'number'}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
                width: '100%',
              }}
              label={'Inventory Margin'}
            />
          )}

          {isShowInputField('*|entity_street|*') && (
            <Input
              value={values?.entity_street}
              setter={(e) => {
                setFieldValue('entity_street', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Entity Street'}
              label={'Entity Street'}
            />
          )}

          {isShowInputField('*|entity_city|*') && (
            <Input
              value={values?.entity_city}
              setter={(e) => {
                setFieldValue('entity_city', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Entity City'}
              label={'Entity City'}
            />
          )}

          {isShowInputField('*|entity_state|*') && (
            <Input
              value={values?.entity_state}
              setter={(e) => {
                setFieldValue('entity_state', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Entity State'}
              label={'Entity State'}
            />
          )}

          {isShowInputField('*|entity_country|*') && (
            <Input
              value={values?.entity_country}
              setter={(e) => {
                setFieldValue('entity_country', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Entity Country'}
              label={'Entity Country'}
            />
          )}

          {isShowInputField('*|entity_zip|*') && (
            <Input
              value={values?.entity_zip}
              setter={(e) => {
                setFieldValue('entity_zip', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Entity Zipcode'}
              label={'Entity Zipcode'}
            />
          )}

          {isShowInputField('*|entity_phone|*') && (
            <Input
              value={values?.entity_phone}
              setter={(e) => {
                setFieldValue('entity_phone', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Entity Phone'}
              label={'Entity Phone'}
            />
          )}

          {isShowInputField('*|listing_brokerage_name|*') && (
            <Input
              value={values?.listing_brokerage_name}
              setter={(e) => {
                setFieldValue('listing_brokerage_name', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Brokerage Firm Name'}
              label={'Brokerage Firm Name'}
            />
          )}

          {isShowInputField('*|listing_brokerage_address|*') && (
            <Input
              value={values?.listing_brokerage_address}
              setter={(e) => {
                setFieldValue('listing_brokerage_address', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Brokerage Firm Address'}
              label={'Brokerage Firm Address'}
            />
          )}

          {isShowInputField('*|Business_Name|*') && (
            <Input
              value={values?.business_name}
              setter={(e) => {
                setFieldValue('business_name', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Business Name'}
              label={'Business Name'}
            />
          )}

          {isShowInputField('*|Business_Address|*') && (
            <Input
              value={values?.business_address}
              setter={(e) => {
                setFieldValue('business_address', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Business Address'}
              label={'Business Address'}
            />
          )}

          {isShowInputField('*|company_name|*') && (
            <Input
              value={values?.company_name}
              setter={(e) => {
                setFieldValue('company_name', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Company Name'}
              label={'Company Name'}
            />
          )}

          {isShowInputField('*|company_address|*') && (
            <Input
              value={values?.company_address}
              setter={(e) => {
                setFieldValue('company_address', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Company Address'}
              label={'Company Address'}
            />
          )}

          {isShowInputField('*|Seller_Name|*') && (
            <DropDown
              setter={(item) => {
                setFieldValue('seller', item);
                setFieldValue('seller_email', item?.email);
                // setFieldValue('seller_address', item?.email);
                setFieldValue('seller_phone', item?.contact);
              }}
              value={values?.seller}
              placeholder={'Seller Name'}
              options={sellers}
              optionLabel={'name'}
              optionValue={'_id'}
              label={'Seller Name'}
            />
          )}

          {isShowInputField('*|seller_email|*') && (
            <Input
              value={values?.seller_email}
              setter={(e) => {
                setFieldValue('seller_email', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Seller Email'}
              label={'Seller Email'}
            />
          )}

          {isShowInputField('*|seller_address|*') && (
            <Input
              value={values?.seller_address}
              setter={(e) => {
                setFieldValue('seller_address', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Seller Address'}
              label={'Seller Address'}
            />
          )}

          {isShowInputField('*|seller_phone|*') && (
            <Input
              value={values?.seller_phone}
              setter={(e) => {
                setFieldValue('seller_phone', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Seller Phone'}
              label={'Seller Phone'}
            />
          )}

          {isShowInputField('*|seller_title|*') && (
            <Input
              value={values?.seller_title}
              setter={(e) => {
                setFieldValue('seller_title', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Seller Title'}
              label={'Seller Title'}
            />
          )}

          {isShowInputField('*|seller_signature_date|*') && (
            <Input
              value={values?.seller_signature_date}
              setter={(e) => {
                setFieldValue('seller_signature_date', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Seller Signature Date'}
              label={'Seller Signature Date'}
              type={'date'}
            />
          )}

          {isShowInputField('*|Buyer_Name|*') && (
            <DropDown
              setter={(item) => {
                setFieldValue('buyer', item);
                setFieldValue('buyer_email', item?.email);
                // setFieldValue('buyer_address', item?.email);
                setFieldValue('buyer_phone', item?.contact);

                const signOrderData = values?.recipients?.filter(
                  (user) => user?.role !== 'Buyer',
                );

                signOrderData.push({
                  send_email: true,
                  send_email_delay: 0,
                  id: item?._id,
                  name: item?.name,
                  email: item?.email,
                  subject: 'Contract Agreement for Signature by SignWell',
                  message: 'Hello, Please review agreement and do signature',
                  role: 'Buyer',
                });

                setFieldValue('recipients', signOrderData);
              }}
              value={values?.buyer}
              placeholder={'Buyer Name'}
              options={buyers}
              optionLabel={'name'}
              optionValue={'_id'}
              label={'Buyer Name'}
            />
          )}

          {isShowInputField('*|buyer_email|*') && (
            <Input
              value={values?.buyer_email}
              setter={(e) => {
                setFieldValue('buyer_email', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Buyer Email'}
              label={'Buyer Email'}
            />
          )}

          {isShowInputField('*|buyer_address|*') && (
            <Input
              value={values?.buyer_address}
              setter={(e) => {
                setFieldValue('buyer_address', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Buyer Address'}
              label={'Buyer Address'}
            />
          )}

          {isShowInputField('*|buyer_phone|*') && (
            <Input
              value={values?.buyer_phone}
              setter={(e) => {
                setFieldValue('buyer_phone', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Buyer Phone'}
              label={'Buyer Phone'}
            />
          )}

          {isShowInputField('*|buyer_title|*') && (
            <Input
              value={values?.buyer_title}
              setter={(e) => {
                setFieldValue('buyer_title', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Buyer Title'}
              label={'Buyer Title'}
            />
          )}

          {isShowInputField('*|buyer_signature_date|*') && (
            <Input
              value={values?.buyer_signature_date}
              setter={(e) => {
                setFieldValue('buyer_signature_date', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Buyer Signature Date'}
              label={'Buyer Signature Date'}
              type={'date'}
            />
          )}

          {isShowInputField('*|broker_name|*') && (
            <DropDown
              setter={(item) => {
                setFieldValue('broker', item);
                setFieldValue('broker_firm_name', item?.company?.name || '');

                const signOrderData = values?.recipients?.filter(
                  (user) => user?.role !== 'Broker',
                );

                signOrderData.push({
                  send_email: true,
                  send_email_delay: 0,
                  id: item?._id,
                  name: item?.name,
                  email: item?.email,
                  subject: 'Contract Agreement for Signature by SignWell',
                  message: 'Hello, Please review agreement and do signature',
                  role: 'Broker',
                });

                setFieldValue('recipients', signOrderData);
              }}
              value={values?.broker}
              placeholder={'Broker Name'}
              options={brokers}
              optionLabel={'name'}
              optionValue={'_id'}
              label={'Broker Name'}
            />
          )}

          {isShowInputField('*|broker_firm_name|*') && (
            <Input
              value={values?.broker_firm_name}
              setter={(e) => {
                setFieldValue('broker_firm_name', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Broker Firm Name'}
              label={'Broker Firm Name'}
            />
          )}

          {isShowInputField('*|broker_provided_info|*') && (
            <div className={classes.col_span_2}>
              <TextArea
                value={values?.broker_provided_info}
                setter={(e) => {
                  setFieldValue('broker_provided_info', e);
                }}
                customStyle={{
                  borderRadius: '10px',
                  border: 'none',
                }}
                inputStyle={{ borderColor: 'none' }}
                placeholder={'Broker Provided Info'}
                label={'Broker Provided Info'}
              />
            </div>
          )}

          {isShowInputField('*|cooperating_brokerage_name|*') && (
            <Input
              value={values?.cooperating_brokerage_name}
              setter={(e) => {
                setFieldValue('cooperating_brokerage_name', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Cooperating Brokerage Name'}
              label={'Cooperating Brokerage Name'}
            />
          )}

          {isShowInputField('*|cooperating_brokerage_address|*') && (
            <Input
              value={values?.cooperating_brokerage_address}
              setter={(e) => {
                setFieldValue('cooperating_brokerage_address', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Cooperating Brokerage Address'}
              label={'Cooperating Brokerage Address'}
            />
          )}

          {isShowInputField('*|commission_percentage|*') && (
            <Input
              value={values?.commission_percentage}
              setter={(e) => {
                if (e === '' || (FLOAT_REGEX.test(e) && e >= 0 && e <= 100)) {
                  setFieldValue('commission_percentage', e);
                }
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Commission Percentage'}
              label={'Commission Percentage'}
            />
          )}

          {isShowInputField('*|commission_payment_terms|*') && (
            <div className={classes.col_span_2}>
              <TextArea
                value={values?.commission_payment_terms}
                setter={(e) => {
                  setFieldValue('commission_payment_terms', e);
                }}
                customStyle={{
                  borderRadius: '10px',
                  border: 'none',
                }}
                inputStyle={{ borderColor: 'none' }}
                placeholder={'Commission Payment Terms'}
                label={'Commission Payment Terms'}
              />
            </div>
          )}

          {isShowInputField('*|cooperating_broker_name|*') && (
            <DropDown
              setter={(item) => {
                setFieldValue('cooperating_broker_name', item);
                setFieldValue('cooperating_broker_email', item?.email);
                setFieldValue('cooperating_broker_phone', item?.contact);
              }}
              value={values?.cooperating_broker_name}
              placeholder={'Cooperating Broker Name'}
              options={cooperatingBroker}
              optionLabel={'name'}
              optionValue={'_id'}
              label={'Cooperating Broker Name'}
            />
          )}

          {isShowInputField('*|cooperating_broker_title|*') && (
            <Input
              value={values?.cooperating_broker_title}
              setter={(e) => {
                setFieldValue('cooperating_broker_title', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Cooperating Broker Title'}
              label={'Cooperating Broker Title'}
            />
          )}

          {isShowInputField('*|cooperating_broker_phone|*') && (
            <Input
              value={values?.cooperating_broker_phone}
              setter={(e) => {
                setFieldValue('cooperating_broker_phone', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Cooperating Broker Phone'}
              label={'Cooperating Broker Phone'}
            />
          )}

          {isShowInputField('*|cooperating_broker_email|*') && (
            <Input
              value={values?.cooperating_broker_email}
              setter={(e) => {
                setFieldValue('cooperating_broker_email', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Cooperating Broker Email'}
              label={'Cooperating Broker Email'}
            />
          )}

          {isShowInputField('*|Agreement_Title|*') && (
            <Input
              value={values?.agreement_title}
              setter={(e) => {
                setFieldValue('agreement_title', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Agreement Title'}
              label={'Agreement Title'}
            />
          )}

          {isShowInputField('*|Agreement_Date|*') && (
            <Input
              value={values?.agreement_date}
              setter={(e) => {
                setFieldValue('agreement_date', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Agreement Date'}
              label={'Agreement Date'}
              type={'date'}
            />
          )}

          {isShowInputField('*|closing_date|*') && (
            <Input
              value={values?.closing_date}
              setter={(e) => {
                setFieldValue('closing_date', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Closing Date'}
              label={'Closing Date'}
              type={'date'}
            />
          )}

          {isShowInputField('*|document_date|*') && (
            <Input
              value={values?.document_date}
              setter={(e) => {
                setFieldValue('document_date', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Document Date'}
              label={'Document Date'}
              type={'date'}
            />
          )}

          {isShowInputField('*|Termination_Reason|*') && (
            <div className={classes.col_span_2}>
              <TextArea
                value={values?.termination_reason}
                setter={(e) => {
                  setFieldValue('termination_reason', e);
                }}
                customStyle={{
                  borderRadius: '10px',
                  border: 'none',
                }}
                inputStyle={{ borderColor: 'none' }}
                placeholder={'Termination Reason'}
                label={'Termination Reason'}
              />
            </div>
          )}

          {isShowInputField('*|Clause_Reference|*') && (
            <div className={classes.col_span_2}>
              <Input
                value={values?.clause_reference}
                setter={(e) => {
                  setFieldValue('clause_reference', e);
                }}
                customStyle={{
                  borderRadius: '10px',
                  border: 'none',
                  padding: 0,
                }}
                inputStyle={{ borderColor: 'none' }}
                placeholder={'Clause Reference'}
                label={'Clause Reference'}
              />
            </div>
          )}

          {isShowInputField('*|Due_Diligence_Deadline|*') && (
            <Input
              value={values?.due_diligence_deadline}
              setter={(e) => {
                setFieldValue('due_diligence_deadline', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Due Diligence Deadline'}
              label={'Due Diligence Deadline'}
            />
          )}

          {isShowInputField('*|Document_List|*') && (
            <Input
              value={values?.document_list}
              setter={(e) => {
                setFieldValue('document_list', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Document List'}
              label={'Document List'}
            />
          )}

          {isShowInputField('*|escrow_agent_name|*') && (
            <DropDown
              setter={(item) => {
                setFieldValue('escrow_agent_name', item);
                setFieldValue('escrow_company_name', item?.company?.name);
              }}
              value={values?.escrow_agent_name}
              options={thirdPartyContacts}
              optionLabel={'name'}
              optionValue={'_id'}
              placeholder={'Escrow Agent Name'}
              label={'Escrow Agent Name'}
            />
          )}

          {isShowInputField('*|escrow_company_name|*') && (
            <Input
              value={values?.escrow_company_name}
              setter={(e) => {
                setFieldValue('escrow_company_name', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Escrow Company Name'}
              label={'Escrow Company Name'}
            />
          )}

          {isShowInputField('*|earnest_money_amount|*') && (
            <Input
              value={values?.earnest_money_amount}
              setter={(e) => {
                if (e === '' || NUMBER_REGEX.test(e)) {
                  setFieldValue('earnest_money_amount', e);
                }
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Earnest Money Amount'}
              label={'Earnest Money Amount'}
            />
          )}

          {isShowInputField('*|purchase_price|*') && (
            <Input
              value={values?.purchase_price}
              setter={(e) => {
                if (e === '' || NUMBER_REGEX.test(e)) {
                  setFieldValue('purchase_price', e);
                }
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Purchase Price'}
              label={'Purchase Price'}
            />
          )}

          {isShowInputField('*|down_payment|*') && (
            <Input
              value={values?.down_payment}
              setter={(e) => {
                if (e === '' || NUMBER_REGEX.test(e)) {
                  setFieldValue('down_payment', e);
                }
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Down Payment'}
              label={'Down Payment'}
            />
          )}

          {isShowInputField('*|loan_at_closing|*') && (
            <Input
              value={values?.loan_at_closing}
              setter={(e) => {
                if (e === '' || NUMBER_REGEX.test(e)) {
                  setFieldValue('loan_at_closing', e);
                }
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Loan At Closing'}
              label={'Loan At Closing'}
            />
          )}

          {isShowInputField('*|note_at_closing|*') && (
            <Input
              value={values?.note_at_closing}
              setter={(e) => {
                if (e === '' || NUMBER_REGEX.test(e)) {
                  setFieldValue('note_at_closing', e);
                }
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Note At Closing'}
              label={'Note At Closing'}
            />
          )}

          {isShowInputField('*|start_date|*') && (
            <Input
              value={values?.start_date}
              setter={(e) => {
                setFieldValue('start_date', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Start Date'}
              label={'Start Date'}
              type={'date'}
            />
          )}

          {isShowInputField('*|end_date|*') && (
            <Input
              value={values?.end_date}
              setter={(e) => {
                setFieldValue('end_date', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'End Date'}
              label={'End Date'}
              type={'date'}
            />
          )}

          {isShowInputField('*|sale_commission_amount|*') && (
            <Input
              value={values?.sale_commission_amount}
              setter={(e) => {
                if (e === '' || NUMBER_REGEX.test(e)) {
                  setFieldValue('sale_commission_amount', e);
                }
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Sale Commission Amount'}
              label={'Sale Commission Amount'}
            />
          )}

          {isShowInputField('*|sale_commission_percentage|*') && (
            <Input
              value={values?.sale_commission_percentage}
              setter={(e) => {
                if (e === '' || (FLOAT_REGEX.test(e) && e >= 0 && e <= 100)) {
                  setFieldValue('sale_commission_percentage', e);
                }
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Sale Commission Percentage'}
              label={'Sale Commission Percentage'}
            />
          )}

          {isShowInputField('*|RE_commission_amount|*') && (
            <Input
              value={values?.RE_commission_amount}
              setter={(e) => {
                if (e === '' || NUMBER_REGEX.test(e)) {
                  setFieldValue('RE_commission_amount', e);
                }
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'RE Commission Amount'}
              label={'RE Commission Amount'}
            />
          )}

          {isShowInputField('*|RE_commission_percentage|*') && (
            <Input
              value={values?.RE_commission_percentage}
              setter={(e) => {
                if (e === '' || (FLOAT_REGEX.test(e) && e >= 0 && e <= 100)) {
                  setFieldValue('RE_commission_percentage', e);
                }
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'RE Commission Percentage'}
              label={'RE Commission Percentage'}
            />
          )}

          {isShowInputField('*|equipment_price|*') && (
            <Input
              value={values?.equipment_price}
              setter={(e) => {
                if (e === '' || NUMBER_REGEX.test(e)) {
                  setFieldValue('equipment_price', e);
                }
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Equipment Price'}
              label={'Equipment Price'}
            />
          )}

          {isShowInputField('*|inventory_price|*') && (
            <Input
              value={values?.inventory_price}
              setter={(e) => {
                if (e === '' || NUMBER_REGEX.test(e)) {
                  setFieldValue('inventory_price', e);
                }
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Inventory Price'}
              label={'Inventory Price'}
            />
          )}

          {isShowInputField('*|term_cash|*') && (
            <Input
              value={values?.term_cash}
              setter={(e) => {
                setFieldValue('term_cash', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Term Cash'}
              label={'Term Cash'}
            />
          )}

          {isShowInputField('*|term_sba|*') && (
            <Input
              value={values?.term_sba}
              setter={(e) => {
                setFieldValue('term_sba', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Term sba'}
              label={'Term sba'}
            />
          )}

          {isShowInputField('*|term_conventional|*') && (
            <div className={classes.col_span_2}>
              <TextArea
                value={values?.term_conventional}
                setter={(e) => {
                  setFieldValue('term_conventional', e);
                }}
                customStyle={{
                  borderRadius: '10px',
                  border: 'none',
                }}
                inputStyle={{ borderColor: 'none' }}
                placeholder={'Term Conventional'}
                label={'Term Conventional'}
              />
            </div>
          )}

          {isShowInputField('*|term_other|*') && (
            <div className={classes.col_span_2}>
              <TextArea
                value={values?.term_other}
                setter={(e) => {
                  setFieldValue('term_other', e);
                }}
                customStyle={{
                  borderRadius: '10px',
                  border: 'none',
                }}
                inputStyle={{ borderColor: 'none' }}
                placeholder={'Term Other'}
                label={'Term Other'}
              />
            </div>
          )}

          {/*  */}
          {isShowInputField('*|buyer_execution_date_1|*') && (
            <Input
              value={values?.buyer_execution_date_1}
              setter={(e) => {
                setFieldValue('buyer_execution_date_1', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Buyer Execution Date 1'}
              label={'Buyer Execution Date 1'}
              type={'date'}
            />
          )}

          {isShowInputField('*|buyer_execution_date_2|*') && (
            <Input
              value={values?.buyer_execution_date_2}
              setter={(e) => {
                setFieldValue('buyer_execution_date_2', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Buyer Execution Date 2'}
              label={'Buyer Execution Date 2'}
              type={'date'}
            />
          )}

          {isShowInputField('*|seller_execution_date|*') && (
            <Input
              value={values?.seller_execution_date}
              setter={(e) => {
                setFieldValue('seller_execution_date', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Seller Execution Date'}
              label={'Seller Execution Date'}
              type={'date'}
            />
          )}

          {isShowInputField('*|date_executed|*') && (
            <Input
              value={values?.date_executed}
              setter={(e) => {
                setFieldValue('date_executed', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Date Executed'}
              label={'Date Executed'}
              type={'date'}
            />
          )}

          {isShowInputField('*|today_date|*') && (
            <Input
              value={values?.today_date}
              setter={(e) => {
                setFieldValue('today_date', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Today Date'}
              label={'Today Date'}
              type={'date'}
            />
          )}

          {/* ------------- Listing Brocker -----------  */}
          {isShowInputField('*|listing_broker_name|*') && (
            <DropDown
              setter={(item) => {
                setFieldValue('listing_broker_name', item);
                setFieldValue('listing_broker_email', item?.email);
                setFieldValue('listing_broker_phone', item?.contact);
              }}
              value={values?.listing_broker_name}
              placeholder={'Listing Broker Name'}
              options={brokers}
              optionLabel={'name'}
              optionValue={'_id'}
              label={'Listing Broker Name'}
            />
          )}

          {isShowInputField('*|listing_broker_title|*') && (
            <Input
              value={values?.listing_broker_title}
              setter={(e) => {
                setFieldValue('listing_broker_title', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Listing Broker Title'}
              label={'Listing Broker Title'}
            />
          )}

          {isShowInputField('*|listing_broker_email|*') && (
            <Input
              value={values?.listing_broker_email}
              setter={(e) => {
                setFieldValue('listing_broker_email', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Listing Broker Email'}
              label={'Listing Broker Email'}
            />
          )}

          {isShowInputField('*|listing_broker_phone|*') && (
            <Input
              value={values?.listing_broker_phone}
              setter={(e) => {
                setFieldValue('listing_broker_phone', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Listing Broker Phone'}
              label={'Listing Broker Phone'}
            />
          )}

          {isShowInputField('*|listing_broker_telephone|*') && (
            <Input
              value={values?.listing_broker_telephone}
              setter={(e) => {
                setFieldValue('listing_broker_telephone', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Listing Broker Telephone'}
              label={'Listing Broker Telephone'}
            />
          )}

          {isShowInputField('*|listing_broker_signature_date|*') && (
            <Input
              value={values?.listing_broker_signature_date}
              setter={(e) => {
                setFieldValue('listing_broker_signature_date', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Listing Broker Signature Date'}
              label={'Listing Broker Signature Date'}
              type={'date'}
            />
          )}
        </Row>

        <div className={classes.btn_main}>
          <Button
            type="submit"
            className={classes.btn}
            label={
              isSubmitting && values?.status === 'draft'
                ? 'Submitting...'
                : 'Save'
            }
            disabled={isSubmitting && values?.status === 'draft'}
            onClick={() => {
              setFieldValue('status', 'draft');
            }}
          />
        </div>
      </form>
    </ModalSkeleton>
  );
};

export default ContractInputFieldModal;
